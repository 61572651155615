/*.App {
  text-align: center;
}

*/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.preview-image {
  cursor: pointer;
}

.output {
  text-align: left;
  white-space: pre-line;
  font-size: 15px;
}
.content-container {
  display: flex;
  align-items: start; /* Aligns children (text and images) to the start of the container */
  flex-direction: column;
  font-size: 15px;
}
.max-dimension {
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.text-container {
  margin-right: 20px; /* Adds some space between text and images */
}
.text-content {
  flex: 1;
  white-space: pre-wrap; /* This will preserve whitespace and line breaks */
}

.images-container {
  display: flex;
  align-items: flex-start;
  gap: 10px; /* Space between images */
}

.image-wrapper {
  margin-top: 2%;
}

.image-wrapper img {
  max-width: 150px; /* Adjust the size as per your requirement */
  height: auto;
}
.App-link {
  color: #61dafb;
}

.result{
  white-space: pre-wrap;
  padding: 0.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
